.order-details {
  display: flex;
}
.react-orders,
.recent-inquiry {
  flex: 50%;
}
.components {
  padding: 10px;
}
h1 {
  text-align: left;
  padding: 10px;
}

.links{
  display: flex;
  padding: 5px;
  flex-direction: column;
  width: 80px;
  height: 80px;
  margin: 10px;
  align-items: end;
  justify-content: space-between;
  background: #f5f5f5 !important;
}
.link-white{
  background: #fff !important;
}

.p1{
  padding: 10px ;
}
.p2{
  padding: 20px ;
}
.p3{
  padding: 30px ;
}

.mr05{margin-right: 5px;}
.mr10{margin-right: 10px;}
.m1 {
  margin:10px;
}
.m2 {
  margin:20px;
}
.m3 {
  margin:30px;
}

.bgwhite{ background: #fff;
     width: 100%;
     margin: 0;
     min-height: 200px;
     }
