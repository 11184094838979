.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  /* padding: 20px; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* height: 100vh; */
  min-height: 100vh;
}
.wrapper > aside {
  flex: 10%;
  background-color: #333;
  color: #fff;
  display: flex;
}
body{overflow-y: scroll !important; padding-right: 0!important;}
.TextAlign{ text-align: left;}
.MarginBt0{margin-bottom: 0 !important;}
.Margintp0{margin-top: 0 !important;}
.MarginBt5{margin-bottom: 5px !important;}
.Margintp5{margin-top: 10px !important;}
.wrapper > section {
  flex: 90%;

  border: 1px solid #ccc;
}
.ui.search >.results,
.searchBar .input {width: 100% !important;}
.searchBar .input .prompt {border-radius: 5px !important;}
nav {
  width: 100%;
}
ul {
  list-style: none;
  padding: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
li {
  list-style: none;
  padding: 0 10px;
  border-bottom: 1px dotted #888;
  width: 100%;
}
li:hover {
  background-color: #555;
}
ul li a {
  color: #fff;
  display: block;
  padding: 5px;
}
ul li a:hover {
  color: yellow;
}
