/* Base Color */
.basecolor :{
    background-color: #fff;
}

/* Margin */
.m0{
    margin: 0
}
.m05{
    margin: 5px
}
.m10{
    margin: 10px
}
.m15{
    margin: 15px
}
.m20{
    margin: 20px
}


.mt0{
    margin-top: 0
}
.mt05{
    margin-top: 5px
}
.mt10{
    margin-top: 10px
}
.mt15{
    margin-top: 15px
}
.mt20{
    margin-top: 20px
}

.mr0{
    margin-right: 0
}
.mr05{
    margin-right: 5px
}
.mr10{
    margin-right: 10px
}
.mr15{
    margin-right: 15px
}
.mr20{
    margin-right: 20px
}


.mb0{
    margin-bottom: 0
}
.mb05{
    margin-bottom: 5px
}
.mb10{
    margin-bottom: 10px
}
.mb15{
    margin-bottom: 15px
}
.mb20{
    margin-bottom: 20px
}

.ml0{
    margin-left: 0
}
.ml05{
    margin-left: 5px
}
.ml10{
    margin-left: 10px
}
.ml15{
    margin-left: 15px
}
.ml20{
    margin-left: 20px
}


/* padding */
.p0{
    padding: 0
}
.p05{
    padding: 5px
}
.p10{
    padding: 10px
}
.p15{
    padding: 15px
}
.p20{
    padding: 20px
}




/* FAB */
.fab_container{
 position: "fixed";
 bottom: 50;
 right: 30;
}
.fab {
    position: fixed;
    top: 0;
    right: 0 ;
    z-index: 10;
}