.container-ct{
    padding: 10px;
}
.Companysearch .input {
    width: 100%;
}
.mb-10 {
    margin-bottom: 10px;
}

.container-create{ width: 70% !important; margin-top: 5%;}
.sub-container{margin-top: 0px !important; padding: 20px  !important;}
.inline-type-input{display: flex; align-items: baseline;
    justify-content: flex-end;
    text-align: end;
}
.field.inline-type-input label {
    flex: .7;
}
.field.inline-type-input input, .field.inline-type-input select,  .field.inline-type-input  .selection.dropdown {
    flex: 4;
}

.pr_inprogress{
    background-color: #ffff1c;
}
.pr_hold{
    background-color: #ffa3a3;
}

.pr_expired{
    background-color: #ffd3d3;
}
.pr_submitted a, .pr_so a, .pr_active a, .pr_hold a,.pr_inprogress a, .pr_expired a{text-decoration: underline; color:black}
.pr_active{
    background-color: #d1fcd1;
}
.pr_so{
    background-color: #85ffda;
}
.pr_submitted{
    background-color: #5efa59;
    /* color:#fff; */
}
.pr_dropped a {color : white; text-decoration: underline;}
.pr_dropped:hover, .pr_dropped{
    background-color: #474747 !important;
    color:#fff;
}
.reportsTotal h5 {font-size: small;}