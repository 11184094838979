.TableControl{
    font-size: 8px;
    display: contents;
    
}


.RowRight {
    padding: 0 2px 0 0;
    border-right: 1px solid #d5d5d5;

 }
